import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Container from '@components/Grid/Container';
import SectionHeading from '@components/SectionHeading/SectionHeading';
import HomeLayout from '@layouts/HomeLayout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = HomeLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <h1>{`About`}</h1>
      <p>{`Adam is a repeat startup founder `}{`&`}{` early employee, having worked at six startups (and counting).`}</p>
      <p>{`He got his first taste of startup life in 2003 while interning at `}<a parentName="p" {...{
          "href": "/startup/sensicast"
        }}>{`Sensicast`}</a>{`.
He would continue working there for the next four years, on his summer and winter school breaks.`}</p>
      <p>{`His interests lie in web, mobile, design, virtual reality, hardware.`}</p>
      <h3>{`Education`}</h3>
      <h4>{`University of Massachusetts Amherst`}</h4>
      <p><em parentName="p">{`M.S. Transportation Engineering (coursework only, no dissertation)`}</em></p>
      <p><em parentName="p">{`B.S. Computer Systems Engineering`}</em></p>
    </Container>
    <SectionHeading text="Education" mdxType="SectionHeading" />
    <Container mdxType="Container">
    </Container>
    <SectionHeading text="Internships" mdxType="SectionHeading" />
    <Container mdxType="Container">
      <h4>{`Sensicast`}</h4>
      <h4>{`MGH Simulation`}</h4>
      <h4>{`Q-Division`}</h4>
      <p><em parentName="p">{`Recording Studio, Cambridge, MA`}</em></p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      